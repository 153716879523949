/* eslint-disable max-len,react/no-danger */
import React from 'react';
import { PageHeader, Seo, Footer } from 'components/common';
import { graphql, StaticQuery, Link } from 'gatsby';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import styles from 'components/commonStyles';
import { siteUrl } from 'configs/constants';
import classnames from 'classnames';
import Img from 'gatsby-image';

const DataDomain = ({ classes }) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(
          filter: {
            extension: { regex: "/(jpg)|(jpeg)|(png)/" }
            relativeDirectory: { eq: "other/data-domain" }
          }
        ) {
          edges {
            node {
              name
              childImageSharp {
                fluid(srcSetBreakpoints: [200, 340, 520, 800, 890], quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const images = data.images.edges.reduce((summ, node) => {
        const { childImageSharp, name } = node.node;

        summ[`${name}`] = childImageSharp.fluid;

        return summ;
      }, {});

      const description = classnames(classes.text, classes.textDescription);

      const Page = () => (

        <div className={classes.content}>
          <p>
            The main idea of srv4pos is to exchange data between POS terminals in
            a various ways:
          </p>
          <div className={classes.picture} style={{ marginBottom: 90 }}>
            <Img fluid={images[1]} style={{ width: 645 }} />
          </div>
          <p>Data domain is very simple:</p>
          <div className={classes.picture} style={{ marginBottom: 30 }}>
            <Img fluid={images[2]} style={{ width: 850 }} />
          </div>
          <p style={{ marginBottom: 90 }}>
            «Commodity» is an object-link between Category and Product.
          </p>
          <p>Companies are isolated from each other:</p>
          <div className={classes.picture} style={{ marginBottom: 90 }}>
            <Img fluid={images[3]} style={{ width: 850 }} />
          </div>
          <p>
            Every company is designated by so-called
            <span className={classes.textBold}> sellerId </span>
            and has Unique Resource Location:
          </p>
          <div className={classes.picture} style={{ marginBottom: 60 }}>
            <Img fluid={images[4]} style={{ width: 850 }} />
          </div>
          <p className={description}>
            Desired API version can be choosen from
            <span className={classes.textColor}>
              <Link to="/developers/release-notes/">
                {' '}
                Release notes.
              </Link>
            </span>
          </p>
          <p className={description}>
            Where to start? Choose business case for your needs at
            <span className={classes.textColor}>
              <Link to="/developers/api-chooser/">
                {' '}
                API chooser
                {' '}
              </Link>
            </span>
          </p>
          <p className={description}>
            Technical guides per laguage are at:
            <span className={classes.textColor}>
              <Link to="/developers/java/"> Java</Link>
              ,
              <Link to="/developers/dot-net/"> .NET</Link>
              ,
              <Link
                to="/developers/json-rest-api-guidelines/"
              >
                {' '}
                JSON-REST.
              </Link>
            </span>
          </p>
          <p className={description}>
            And make sure you aware about
            <span className={classes.textColor}>
              <Link to="/developers/error-handling/">
                {' '}
                Error handling.
              </Link>
            </span>
          </p>
        </div>
      );

      return (
        <>
          <Seo title="Data domain" url={`${siteUrl}/data-domain`} />
          <PageHeader />
          <Page />
          <Footer />
        </>
      );
    }}
  />
);

DataDomain.propTypes = {
  classes: PropTypes.shape({
    content: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    textBold: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    textDescription: PropTypes.string.isRequired,
    picture: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles, { withTheme: true })(DataDomain);
